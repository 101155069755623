import GlobalSettings from '@/GlobalSettings'
import store from '../../store'
import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'

const entity = {
  id: {
    type: IKDataEntity.Types.Integer,
    displayName: 'id',
    tableConfig: {
      align: 'start',
    },
    form: false,
    header: false,
  },
  photo: {
    displayName: 'image',
    type: IKDataEntity.Types.Image,
    formConfig: {
      type: {
        root: () => store.getters.rootUrl + GlobalSettings.servantRoot,
      },
      requiredEdit: false,
      requiredNew: false,
      md: 12,
    },
    header: false,
  },
  name: {
    displayName: 'servantName',
  },
  password: {},
  permission: {
    type: IKDataEntity.Types.Boolean,
    formConfig: {
      default: true,
    },
    form: false,
  },
  isPartTime: {
    type: IKDataEntity.Types.Boolean,
    formConfig: {
      default: true,
    },
    form: false,
  },
}
const config = {
  add: function (item) {
    item.permission = 1
    item.isPartTime = 1
    return hillo.postWithUploadFile('Servant.php?op=add', item, {
      showLoading: true,
    })
  },
  edit: function (item) {
    if (item.file) {
      item.imageExt = item.file.name.split('.').lastItem
    }
    return hillo.postWithUploadFile('Servant.php?op=update', item, {
      showLoading: true,
    })
  },
  remove: function (id) {
    return hillo.post('Servant.php?op=delete', { id: id })
  },
  load: async function (filter) {
    return (await hillo.get('Servant.php', { lang: GlobalSettings.getLang(), ...filter }))
      .content
  },
}

export default IKDataEntity.ModelFactory(entity, config)
