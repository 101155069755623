import GlobalSettings from '@/GlobalSettings'

import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'
import ConsumeType from '../buffet/ConsumeType'
import Payment from '../common/Payment'
import Servant from '@/model/tableAndPerson/Servant'
import PaymentColors from '@/model/common/PaymentColors'

const entity = {
  paymentArr: {
    type: IKDataEntity.Types.Option,
    formConfig: {
      type: {
        selectItems: Payment.getList,
        multiple: true,
        color: PaymentColors.getList(),
      },
    },
    tableConfig: {
      width: '220px',
    },
    displayName: 'payMethod',
  },
  orderId: {
    type: IKDataEntity.Types.Integer,
    displayName: 'order_ID', // 订单编号
    tableConfig: {
      align: 'start',
    },
    formConfig: {
      disableNew: true,
      disableEdit: true,
      requiredNew: false,
    },
  },
  tableName: {
    tableConfig: {
      sortable: true,
    },
  },
  servantName: {
    type: IKDataEntity.Types.Option,
    formConfig: {
      type: {
        selectItems: Servant.getList,
        itemText: 'name',
        itemValue: 'name',
      },
    },
    displayName: 'servantName',
    header: false,
  },

  consumeTypeId: {
    type: IKDataEntity.Types.Option,
    formConfig: {
      type: {
        selectItems: ConsumeType.getList,
      },
    },
  },
  updateTimestamp: {
    displayName: 'checkout_time', // 结账时间
    type: IKDataEntity.Types.Date,
  },
  totalPrice: {
    type: IKDataEntity.Types.Float,
    tableConfig: {
      sortable: true,
    },
  },

}

const config = {
  load: async function (filter) {
    const timespan = filter[0].dateFilter.join(' - ')
    const list = (await hillo.get('Orders.php', {
      op: 'withSortAndFilter',
      lang: GlobalSettings.getLang(),
      timespan: timespan,
    })).content

    return list.map(function (item) {
      if (item.payLogs) {
        item.paymentArr = item.payLogs.map(n => parseInt(n.paymentMethodId)).join(',')
      } else {
        item.paymentArr = '0'
      }
      item.id = item.orderId
      return item
    })
  },
}
export default IKDataEntity.ModelFactory(entity, config)
